/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import PageWidth from "./page-width"

import {Banner} from "../../types"

import {Theme} from "../styles/theme"
import {ButtonStyles} from "../styles/button-styles"

interface Props extends Banner {
  size: "small" | "large"
  className?: string
  index?: number
}

const SingleBanner = (props: Props): JSX.Element => {
  return (
    <div
      className={props.className}
      css={[
        BannerStyles,
        props.size == "large" ? LargeBannerStyles : SmallBannerStyles,
        !props.index || props.index === 0
          ? MainBannerStyles
          : SecondaryBannerStyles,
      ]}
    >
      <PageWidth>
        <div>
          <h1>{props.bannerTitle}</h1>
          {props.bannerText && <p>{props.bannerText}</p>}
          {props.bannerCta && (
            <a css={ButtonStyles({type: "white"})} href={props.bannerCta.slug}>
              <FontAwesomeIcon icon={["far", "angle-right"]} />
              {props.bannerCtaText}
            </a>
          )}
        </div>
        {props.bannerImage && (
          <figure
            style={{backgroundImage: `url(${props.bannerImage.url}?w=1600)`}}
          />
        )}
      </PageWidth>
    </div>
  )
}

export default SingleBanner

const BannerStyles = css`
  position: relative;
  width: 100%;
  display: flex;
  z-index: 5;
  color: white;

  figure {
    position: absolute;
    background-size: cover;
    background-position: center;
    width: 50%;
    content: " ";
    z-index: -1;
    right: 0;

    @media ${Theme.breakpoints.mediumDown} {
      position: static;
    }
  }

  .page-width {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      max-width: 45%;
      padding: 3rem 3rem 3rem 0;

      h1 {
        margin-bottom: 1rem;
      }

      p {
        max-width: 50rem; // IE11
        max-width: min(40vw, 50rem);
        margin-bottom: 3rem;
        white-space: break-spaces;
        font-size: 2rem;
        line-height: 3.1rem;
        font-weight: 400;

        @media ${Theme.breakpoints.mediumDown} {
          font-size: 1.7rem;
        }
      }
    }
  }

  @media ${Theme.breakpoints.mediumDown} {
    margin-bottom: 6rem;

    .page-width {
      top: -6rem;
      flex-direction: column;

      > div {
        max-width: 100%;
        margin-top: 2rem;
        padding: 0;

        h2 {
          margin: 0;
        }

        p {
          max-width: 100%;
          margin-bottom: 1rem;
        }
      }

      @media ${Theme.breakpoints.mediumDown} {
        h2 {
          font-size: 2.5rem;
          font-weight: 700;
        }

        figure {
          width: calc(100% + 6rem);
          margin: 3rem 0 0;
        }
      }
    }
  }
`

const LargeBannerStyles = css`
  min-height: 60rem;
  margin-bottom: 8rem;

  h1 {
    font-size: 5rem;
  }

  figure {
    height: 100%;
    bottom: 0;
  }

  @media ${Theme.breakpoints.mediumDown} {
    min-height: unset;

    figure {
      height: 20rem;
    }
  }
`

const SmallBannerStyles = css`
  min-height: 24.5rem;
  margin-bottom: 6rem;

  figure {
    height: 100%;
  }

  @media ${Theme.breakpoints.mediumDown} {
    min-height: unset;

    figure {
      height: 20rem;
    }
  }
`

const MainBannerStyles = css`
  &:before {
    content: " ";
    position: absolute;
    width: 57%;
    height: 100%;
    background-color: ${Theme.colors.identity.primary};
    z-index: -1;
    left: 0;
  }

  @media ${Theme.breakpoints.mediumDown} {
    &:before {
      width: 100%;
    }
  }
`

const SecondaryBannerStyles = css`
  @media ${Theme.breakpoints.mediumDown} {
    display: none;
  }
`
